<template>
  <div>
    <!-- <h2>rr{{ this.$route.params.org_id }}</h2> -->
    <!--{{ this.$route.params.group_id }} -->

    <b-card-code>
      <div class="mb-3 d-flex justify-content-between">
        <h6>
          Sample file :
          <!-- <a href="files/sample_report.csv" download>
            Click here to download sample file.
          </a> -->
          <a @click="openSampleFile()">Click here to download sample file.</a>
        </h6>

        <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
      </div>
      <validation-observer ref="NessusUploadForm">
        <b-form class="auth-login-form mt-2" @submit.prevent="uploadReport">
          <!-- Organization-->

          <b-form-group
            label="Organization"
            label-for="Organization"
            v-if="is_staff"
          >
            <!-- <validation-provider
              #default="{ errors }"
              name="organization"
              rules="required"
            >
              :disabled="showOrg"
              <b-form-select
                v-model="organization"
                :options="organizations"
                :state="errors.length > 0 ? false : null"
                @change="onOrgChange"
                disabled
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
            <v-select
              v-model="organization"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              @input="onOrgChange"
              autocomplete
              style="width: 100%"
              :reduce="(organization) => organization.tenant_uid"
              disabled
            />
          </b-form-group>
          <b-form-group label="Report Type" label-for="Report Type">
            <template #label>
              <span>Report Type</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Report Type"
              rules="required"
            >
              <b-form-select
                v-model="report_type"
                :options="report_type_options"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Group-->
          <b-form-group label-for="Group">
            <template #label>
              <span>Group</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
              <b-button
                class="ml-1"
                style="padding: 0.186rem 1rem !important"
                @click="gotoCreateGroup()"
                variant="outline-success"
                size="sm"
                >+ Add Asset Group</b-button
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="group"
              rules="required"
            >
              <b-form-select
                v-model="group"
                :options="groups"
                :state="errors.length > 0 ? false : null"
                :disabled="showGroup"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- {{ group }} -->
          <!-- Team-->
          <b-form-group
            label="Team [Vulnerabilities will be auto-assigned to the members of the team selected]"
            label-for="Team"
          >
            <b-form-select
              v-model="team"
              :options="teams"
              @change="onTeamChange"
              @click.native="loadTeams"
            ></b-form-select>
          </b-form-group>
          <!-- Team-->
          <b-form-group
            label="Assign To [Vulnerabilities will be auto-assigned to the selected user only]"
            label-for="Team Member"
          >
            <b-form-select
              v-model="assign_to"
              :options="teamMembers"
            ></b-form-select>
          </b-form-group>
          <!-- Asset Group -->
          <b-form-group label="Asset Exposure">
            <template #label>
              <span>Asset Exposure</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="asset_group_type"
              rules="required"
            >
              <b-form-select
                v-model="asset_group_type_upload"
                :options="assetGroupTypes"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="asset_group_type_upload === 'internal'">
            <template #label>
              <span>Network Location</span>
              <b-button
                class="ml-1"
                style="padding: 0.186rem 1rem !important"
                @click="gotoCreateNetwork()"
                variant="outline-success"
                size="sm"
                >+ Add Network Location</b-button
              >
            </template>
            <v-select
              v-model="networkLocation"
              label="text"
              placeholder="--Select Network Location--"
              :options="networkLocationOptions"
              autocomplete
              :reduce="(networkLocation) => networkLocation.value"
              :clearable="false"
            />
            <!-- {{typeof(networkLocation)}} -->
          </b-form-group>
          <!--Upload File -->
          <b-form-group label="Upload File" label-for="File">
            <template #label>
              <span>Upload File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <b-form-file
              v-model="nessus_report"
              :state="Boolean(nessus_report)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <div class="mt-3">
              Selected file: {{ nessus_report ? nessus_report.name : "" }}
            </div>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="
              disable ||
              group === null ||
              nessus_report === null ||
              report_type === ''
            "
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Upload</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>

    <b-card-code title="">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h4>Uploads</h4>
        <!-- <div v-if="is_staff">
          <v-select
            v-model="selectedOrganization"
            label="name"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="loadUploads"
            autocomplete
            style="width: 100%"
            :reduce="(organization) => organization.tenant_uid"
            disabled
          />
        </div> -->
      </div>
      <!-- {{ items }} -->
      <b-table
        responsive
        id="uploadsTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        hover
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(is_processed)="data">
          <b-badge variant="primary" v-if="data.item.is_processed == 0"
            >Queued</b-badge
          >
          <b-badge variant="warning" v-if="data.item.is_processed == 1"
            >In Progress</b-badge
          >
          <b-badge variant="success" v-if="data.item.is_processed == 2"
            >Processed</b-badge
          >
          <b-badge
            variant="danger"
            style="cursor: pointer"
            :title="data.item.errors"
            v-if="data.item.is_processed == 3"
            >Failed</b-badge
          >
        </template>
        <template #cell(stats)="data">
          <b-badge v-if="data.item.is_processed != 0" variant="success"
            >New Assets: {{ data.item.assets_new }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="secondary"
            >Duplicate Assets: {{ data.item.assets_dpl }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="success"
            >New Vulnerabilities: {{ data.item.vulnerabilities_new }}</b-badge
          ><br />
          <b-badge v-if="data.item.is_processed != 0" variant="secondary"
            >Vulnerabilities Retained:
            {{ data.item.vulnerabilities_dpl }}</b-badge
          >
        </template>
        <template #cell(activity_time)="data">
          <span>{{ data.item.created_at | formatdate }}</span>
        </template>
        <template #cell(actions)="data">
          <button
            @click="deleteUpload(data.item.nsr_id)"
            type="button"
            class="btn ml-auto btn-danger btn-sm"
          >
            Delete
          </button>
        </template>
      </b-table>
      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :link-gen="linkGen"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="uploadsTable"
        use-router
      />
    </b-card-code>
    <b-modal
      ref="modal-new-network-location"
      title="Create New Network Location"
      hide-footer
      size="lg"
    >
      <AddNetworkLocation
        :redirectNetwork="redirectNetwork"
        :closeSidebar="closeNetworkModal"
      />
    </b-modal>
    <b-modal
      ref="modal-new-group"
      title="Create New Group"
      hide-footer
      size="lg"
    >
      <AddAssetGroup
        :is_asset_group="is_asset_group"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      ref="modal-sample-files"
      title="Sample Files"
      hide-footer
      size="lg"
    >
      <!-- <div class="my-1">
        <span class="my-1">
          Nessus file :
          <a href="files/sample_report.csv" download>Download sample file. </a>
        </span>

        <br />
        <span class="my-1">
          Qualys file :
          <a href="files/sample_report.csv" download>Download sample file. </a>
        </span>
      </div> -->
      <div class="modal-content-container">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Nessus file:</span>
            <b-button variant="outline-primary" size="sm" href="files/sample_report.csv" download
              >Download sample file</b-button
            >
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>Qualys file:</span>
            <b-button variant="outline-primary" size="sm" href="files/sample_qualys_report.csv" download
              >Download sample file</b-button
            >
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BTable,
  BPaginationNav,
  BBadge,
  BSpinner,
  BModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import AddNetworkLocation from "../NetworkLocation/add.vue";
import AddAssetGroup from "../AssetGroup/add.vue";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BTable,
    BPaginationNav,
    BBadge,
    BSpinner,
    vSelect,
    BModal,
    AddNetworkLocation,
    AddAssetGroup,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      group: this.$route.params.group_id ? this.$route.params.group_id : null,
      team: null,
      assign_to: null,
      groups: [{ value: null, text: "--Select Group--" }],
      teams: [{ value: null, text: "--Select Team--" }],
      teamMembers: [{ value: null, text: "--Select Member--" }],
      users: [{ value: null, text: "--Select Owner--" }],
      nessus_report: null,
      selectedOrganization: this.$store.state.app.org_id,
      // organization: this.$route.params.org_id
      //   ? this.$route.params.org_id
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: [{ value: null, text: "--Select Organization--" }],

      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        // { key: "nsr_id", label: "ID" },
        { key: "index", label: "#" },
        { key: "group_id.group_name", label: "Group" },
        { key: "team_id.team_name", label: "Team" },
        { key: "is_processed", label: "Status" },
        { key: "stats", label: "Stats" },
        { key: "organization", label: "Organization" },
        { key: "activity_time", label: "Upload Time" },
        { key: "user_id.full_name", label: "Uploaded By" },
        { key: "actions", label: "Actions" },
      ],
      filter: [],
      showOrg: false,
      showGroup: false,
      isloading: false,
      disable: false,
      // group_id: "",
      asset_group_type_upload: "external",
      assetGroupTypes: [
        // { value: null, text: "--Select Asset Exposure--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      networkLocationOptions: [],
      networkLocation: null,
      redirectNetwork: true,
      is_asset_group: true,
      report_type: "",
      report_type_options: [
        { value: "nessus", text: "Nessus" },
        { value: "qualys", text: "Qualys" },
      ],
    };
  },
  mounted() {
    this.$route.params.org_id;
    this.$route.params.group_id;
  },
  created: function () {
    this.load();
    this.loadUploads();
    if (this.$route.params.group_id) {
      this.showOrg = true;
      this.showGroup = true;
      this.onOrgChange();
    } else if (this.$store.state.app.user.is_staff) {
      this.onOrgChange();
    } else {
    }
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadUploads();
    },
    asset_group_type_upload: function (newAssetGroupType, oldAssetGroupType) {
      if (newAssetGroupType === "internal") {
        this.loadnetworkLocation();
      }
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    loadUploads: function () {
      this.filter = [];
      let url = process.env.VUE_APP_BASEURL + "asset/nessus-uploads?query=true";
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (this.params_group_id != null) {
        url = url + "&group_id=" + this.params_group_id;
      }
      url = url + "&page=" + this.currentPage;

      if (this.selectedOrganization != null) {
        // this.filter.push({ organization: this.selectedOrganization });
        url = url + "&org_id=" + this.selectedOrganization;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results, "orgddddddddddddd");
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    loadGroup: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/group",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.results.map(function (value, key) {
          let g = {
            value: res.data.results[key].group_id,
            text: res.data.results[key].group_name,
          };
          // console.log(res.data[key].profile.organization)
          self.groups.push(g);
        });
      });
      // //  Load Teams
      // const t_options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url: process.env.VUE_APP_BASEURL + "user/teams",
      // };
      // var self = this;
      // this.$http(t_options).then((res) => {
      //   res.data.map(function (value, key) {
      //     let g = {
      //       value: res.data[key].team_id,
      //       text: res.data[key].team_name,
      //     };
      //     self.teams.push(g);
      //   });
      // });
    },
    loadTeams() {
      let url = process.env.VUE_APP_BASEURL + "user/teams";
      if (!this.$route.params.group_id) {
        url = url + "?org_id=" + this.organization;
      }
      const t_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(t_options).then((res) => {
        this.teams = [];
        res.data.map(function (value, key) {
          let g = {
            value: res.data[key].team_id,
            text: res.data[key].team_name,
          };
          self.teams.push(g);
        });
      });
    },
    gotoCreateGroup: function () {
      this.$refs["modal-new-group"].show();
    },
    openSampleFile: function () {
      this.$refs["modal-sample-files"].show();
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function () {
      this.loadUploads();
      // this.teamMembers = [];
      if (this.is_staff) {
        // Load oragnization drop down
        const org_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org?q_org_id=" +
            this.organization,
        };
        var self = this;
        this.$http(org_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].sso_ref_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
        });
      } else {
        // Load Groups
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/group",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.results.map(function (value, key) {
            let g = {
              value: res.data.results[key].group_id,
              text: res.data.results[key].group_name,
            };
            // console.log(res.data[key].profile.organization)
            self.groups.push(g);
          });
        });
        //  Load Teams
        // if (!this.$route.params.group_id) {
        //   const t_options = {
        //     method: "GET",
        //     headers: { "content-type": "application/json" },
        //     url:
        //       process.env.VUE_APP_BASEURL +
        //       "user/teams?org_id=" +
        //       this.organization,
        //   };
        //   var self = this;
        //   this.$http(t_options).then((res) => {
        //     res.data.map(function (value, key) {
        //       let g = {
        //         value: res.data[key].team_id,
        //         text: res.data[key].team_name,
        //       };

        //       self.teams.push(g);
        //     });
        //   });
        // }
      }
    },
    onTeamChange: function () {
      if (this.team != null) {
        this.teamMembers = [{ value: null, text: "--Select Member--" }];
        let url =
          process.env.VUE_APP_BASEURL + "user/users/get-all-users/?query=true";
        if (this.is_staff) {
          url = url + "&q_org_id=" + this.organization;
        }
        if (this.team != null) {
          url = url + "&team_id=" + this.team;
        }
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let g = {
              value: res.data[key].id,
              text:
                res.data[key].full_name.charAt(0).toUpperCase() +
                res.data[key].full_name.slice(1),
            };
            // console.log(res.data[key].profile.organization)
            self.teamMembers.push(g);
          });
        });
      } else {
        this.assign_to = null;
        this.teamMembers = [{ value: null, text: "--Select Member--" }];
      }
    },
    onOrgChange: function () {
      this.groups = [];
      this.teams = [{ value: null, text: "--Select Team--" }];
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.results.map(function (value, key) {
          let g = {
            value: res.data.results[key].group_id,
            text: res.data.results[key].group_name,
          };
          // console.log(res.data[key].profile.organization)
          self.groups.push(g);
        });
      });
      // const t_options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_BASEURL +
      //     "user/teams?org_id=" +
      //     this.organization,
      // };
      // var self = this;
      // this.$http(t_options).then((res) => {
      //   res.data.map(function (value, key) {
      //     let g = {
      //       value: res.data[key].team_id,
      //       text: res.data[key].team_name,
      //     };
      //     self.teams.push(g);
      //   });
      // });
    },
    refreshUploadForm() {
      this.group = null;
      this.asset_group_type_upload = "external";
      this.team = null;
      this.assign_to = null;
      this.nessus_report = null;
      this.$refs.NessusUploadForm.reset();
    },
    uploadReport: function () {
      this.$refs.NessusUploadForm.validate().then((success) => {
        this.isloading = true;
        this.disable = true;
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Processing your request. Please wait!",
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          //call to axios
          console.log("success");
          let formData = new FormData();
          formData.append("file", this.nessus_report);
          formData.append("group_id", this.group);
          formData.append("asset_exposure", this.asset_group_type_upload);
          formData.append("report_type", this.report_type);
          if (this.team) formData.append("team_id", this.team);
          if (this.assign_to) formData.append("assign_to", this.assign_to);
          if (this.asset_group_type_upload == "internal") {
            formData.append("network_location", this.networkLocation);
          }

          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/org-asset/upload-vuln-report/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.loadUploads();
            console.log(res);
            var toast_title = "Successfully queued the request. ";
            var toast_variant = "success";
            if (res.data.status == "success") {
              //   this.$router.push({name: 'Assets'});
            } else {
              toast_title = res.data.message;
              toast_variant = "danger";
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: toast_title,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: toast_variant,
              },
            });
            this.isloading = false;
            this.disable = false;
            this.refreshUploadForm();
          });
        }
      });
    },
    handleFileUpload() {
      this.nessus_report = this.$refs.file.files[0];
    },
    deleteUpload(id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/nessus-uploads/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.loadUploads();
          //if(res.data.org_id){
          // this.$router.push({name: 'Organizations'});
          // }
        });
      }
    },
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));

        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        if (defaultIndex !== -1) {
          this.networkLocation =
            this.networkLocationOptions[defaultIndex].value;
        } else if (this.networkLocationOptions.length > 0) {
          // If "DEFAULT" is not found, fallback to selecting the first item
          this.networkLocation = this.networkLocationOptions[0].value;
        }
      });
    },
    gotoCreateNetwork() {
      this.$refs["modal-new-network-location"].show();
    },
    closeNetworkModal() {
      this.$refs["modal-new-network-location"].hide();
      this.loadnetworkLocation();
    },
    closeModal() {
      this.$refs["modal-new-group"].hide();
      this.onOrgChange();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.modal-content-container {
  padding: 15px;
}
</style>
